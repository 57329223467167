<template>
  <div class="location-pay-settings__wrapper">
    <ui-loading v-if="loading.data" />
    <validation-observer
      v-else
      tag="div"
      v-slot="{ validate }"
      class="location-pay-settings"
    >
      <template v-if="showSelectPayTextSection">
        <div class="location-pay-settings__subtitle">
          Captions
        </div>

        <div class="location-pay-settings__inputs">
          <validation-provider
            tag="div"
            :name="$t('form.labels.caption.sublocations')"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
            class="location-pay-settings__field"
          >
            <ui-input
              v-model="form.selectSubLocationText"
              :label="$t('form.labels.caption.sublocations')"
              class="location-pay-settings__input"
              required
              :error="errors[0]"
            />
            <img src="@/assets/images/pay/pay-sublocations.svg">
          </validation-provider>

          <validation-provider
            tag="div"
            :name="$t('form.labels.caption.groups')"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
            class="location-pay-settings__field"
          >
            <ui-input
              v-model="form.selectGroupText"
              :label="$t('form.labels.caption.groups')"
              class="location-pay-settings__input"
              required
              :error="errors[0]"
            />
            <img src="@/assets/images/pay/pay-groups.svg">
          </validation-provider>

          <validation-provider
            tag="div"
            :name="$t('form.labels.caption.staffList')"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
            class="location-pay-settings__field"
          >
            <ui-input
              v-model="form.selectStaffText"
              :label="$t('form.labels.caption.staffList')"
              class="location-pay-settings__input"
              required
              :error="errors[0]"
            />
            <img src="@/assets/images/pay/pay-staff-list.svg">
          </validation-provider>
        </div>
      </template>

      <div class="location-pay-settings__subtitle">
        Banner on the payment success page
      </div>

      <div class="location-pay-settings__inputs">
        <validation-provider
          tag="div"
          :name="$t('form.labels.selectBanner')"
          mode="passive"
          v-slot="{ errors }"
          class="location-pay-settings__field"
        >
          <ui-select
            v-model="form.bannerVariant"
            :items="BANNER_VARIANTS"
            :label="$t('form.labels.selectBanner')"
            :placeholder="$t('form.placeholders.select')"
            filterable
            :error="errors[0]"
          />
        </validation-provider>
      </div>

      <ui-button
        action
        type="primary"
        :is-loading="loading.form"
        :disabled="loading.form"
        class="location-pay-settings__submit"
        @click.prevent="submit(validate)"
      >
        {{ $t('form.buttons.save') }}
      </ui-button>
    </validation-observer>
  </div>
</template>

<script>
import api from '@/api';
import { PAYMENT_PAGE_TYPE } from '@/config/paymentPageType';

const BANNER_VARIANTS = [
  {
    value: null,
    name: 'Hidden',
  },
  {
    value: 'VARIANT_1',
    name: 'Variant 1',
  },
  {
    value: 'VARIANT_2',
    name: 'Variant 2',
  },
];

export default {
  name: 'LocationPaySettings',

  props: {
    location: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: {
        data: false,
        form: false,
      },
      form: {
        selectSubLocationText: '',
        selectGroupText: '',
        selectStaffText: '',
        bannerVariant: null,
      },
      BANNER_VARIANTS,
    };
  },

  computed: {
    showSelectPayTextSection() {
      return this.location.isMasterLocation || [PAYMENT_PAGE_TYPE.LIST, PAYMENT_PAGE_TYPE.GROUPS].includes(this.location.paymentPage);
    },
  },

  methods: {
    async loadPaySettings() {
      this.loading.data = true;

      try {
        const { data } = await api.locations.getPaySettingsConfig(this.location.id);

        this.form.selectSubLocationText = data.selectSubLocationText;
        this.form.selectGroupText = data.selectGroupText;
        this.form.selectStaffText = data.selectStaffText;
        this.form.bannerVariant = data.bannerVariant;
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.data = false;
      }
    },

    async submit(validate) {
      if (!await validate()) {
        return;
      }

      this.loading.form = true;

      try {
        await api.locations.setPaySettingsConfig(
          this.location.id,
          {
            selectSubLocationText: this.form.selectSubLocationText.trim(),
            selectGroupText: this.form.selectGroupText.trim(),
            selectStaffText: this.form.selectStaffText.trim(),
            bannerVariant: this.form.bannerVariant,
            workplaceId: this.location.id,
          },
        );

        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.form = false;
      }
    },
  },

  mounted() {
    this.loadPaySettings();
  },
};
</script>

<style lang="scss">
.location-pay-settings {
  display: flex;
  flex-direction: column;
  gap: 18px;

  &__subtitle {
    color: #777;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  &__inputs {
    display: flex;
    gap: 30px;
    width: 100%;

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }

  &__field {
    width: 295px;
    max-width: 100%;

    @media screen and (max-width: 991px) {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  &__submit {
    width: 295px;
    max-width: 100%;
    margin-top: auto;
  }
}
</style>
