<template>
  <div>
    <ui-modal
      :show="show"
      class="subscription-manage-modal"
      :is-loading="plansLoading || subscriptionLoading"
      :no-footer="disableForm"
      @opened="onOpened"
      @closed="onClosed"
    >
      <template #header>
        <div class="subscription-manage-modal__title">
          Manage subscriptions
        </div>
      </template>
      <div class="subscription-manage-modal__body">
        <ui-select
          v-model="form.holderId"
          :items="selectorOwners"
          label="Subscription holder"
          :disabled="disableForm"
        />
        <ui-select
          v-model="form.planId"
          :items="plansSelectorItems"
          label="Subscription plan"
          :disabled="disableForm"
        />
        <template v-if="currentSubscription">
          <div class="subscription-manage-modal__title">
            Billing info
          </div>
          <div
            v-if="currentSubscriptionIsDraft"
            class="subscription-manage-modal__stub"
          >
            There is no subscription for this<br/>
            Subscription holder yet.
          </div>
          <ui-list-info
            v-else
            :list="billingList"
            @remove="removeInfoHandler"
          />
        </template>
        <template v-if="form.planId">
          <div class="subscription-manage-modal__title">
            Pricing
          </div>
          <ui-list-info
            :list="pricingList"
          />
        </template>
        <template v-if="form.holderId">
          <div class="subscription-manage-modal__title">
            Venues
          </div>
          <div class="subscription-manage-modal__divider" />
          <ui-list-info
            v-if="workplacesList.length"
            :list="workplacesList"
          />
          <div
            v-if="!isSimilarLocationTypes"
            class="subscription-manage-modal__alert"
          >
            <div>
              <ui-icon
                name="square-exclamation"
                size="28"
              />
            </div>
            <div>
              Subscription can only be created for locations with the same tips collection type, either Common or Individual.
            </div>
          </div>
        </template>
      </div>
      <template #footer="{ close }">
        <div v-if="!disableForm" class="subscription-manage-modal__footer">
          <ui-button
            size="sm"
            outline
            type="primary"
            class="subscription-manage-modal__footer-button"
            @click="close"
          >
            Cancel
          </ui-button>
          <ui-button
            size="sm"
            type="primary"
            class="subscription-manage-modal__footer-button"
            :is-loading="submitLoading"
            :disabled="!form.planId || !form.holderId || !isSimilarLocationTypes"
            @click="submitSubscription"
          >
            Save
          </ui-button>
        </div>
      </template>
    </ui-modal>

    <ui-modal-alert
      v-model="showRemoveSubscriptionConfirm"
      title="Delete draft subscription?"
      message="Please confirm you want to delete unpaid subscription for the venue."
      apply-button="Confirm"
      close-button="Cancel"
      @apply="removeSubscriptionHandler"
    />
  </div>
</template>
<script>
import money from '@/filters/money';
import api from '@/api';
import { SUBSCRIPTION_STATUSES } from '@/config/subscriptionStatuses';
import { LOCATION_STATUSES } from '@/config/locationStatuses';

const INITIAL_FORM = {
  holderId: null,
  planId: null,
};

const SUBSCRIPTION_INFO = {
  PAYMENT_DETAILS: 'PAYMENT_DETAILS',
  SUBSCRIPTION_VENUES: 'SUBSCRIPTION_VENUES',
  LAST_PAYMENT: 'LAST_PAYMENT',
  EMAIL: 'EMAIL',
  SUBSCRIPTION_STATUS: 'SUBSCRIPTION_STATUS',
};

export default {
  name: 'SubscriptionManageModal',

  props: {
    currentSubscriptionId: {
      type: String,
      default: '',
    },
    owners: {
      type: Array,
      default: () => [],
    },
    locationId: {
      type: Number,
      required: true,
    },
    show: Boolean,
  },

  model: {
    prop: 'show',
    event: 'input',
  },

  data() {
    return {
      form: { ...INITIAL_FORM },
      plans: [],
      currentSubscription: null,
      subscriptionLoading: false,
      submitLoading: false,
      plansLoading: false,
      showRemoveSubscriptionConfirm: false,
    };
  },

  computed: {
    plansSelectorItems() {
      return this.plans.map((plan) => ({ value: plan.id, name: `${plan.name}, ${plan.period}` }));
    },
    selectedPlan() {
      return this.plans.find((plan) => plan.id === this.form.planId);
    },
    pricingList() {
      const pricing = this.currentSubscription?.pricing || this.selectedPlan?.pricing;
      if (pricing) {
        return [
          {
            label: 'Price per venue/month',
            value: money(pricing.monthlyPayment.amount / this.currentSubscription?.quantity, pricing.monthlyPayment.currency),
            visible: this.currentSubscription?.quantity,
          },
          {
            label: 'Venues',
            value: this.currentSubscription?.quantity,
            visible: this.currentSubscription?.quantity,
          },
          {
            label: 'Amount/month',
            value: money(pricing.monthlyPayment.amount, pricing.monthlyPayment.currency),
            visible: true,
          },
          {
            label: 'Amount to pay',
            value: money(pricing.totalPayment.amount, pricing.totalPayment.currency),
            bold: true,
            visible: true,
          },
          {
            label: 'Saving',
            value: money(pricing.discount.amount, pricing.discount.currency),
            visible: true,
          },
        ].filter((info) => info.visible);
      }

      return [];
    },
    billingList() {
      return this.currentSubscription ? [
        {
          id: SUBSCRIPTION_INFO.PAYMENT_DETAILS,
          label: 'Payment details',
          value: this.currentSubscription.status === SUBSCRIPTION_STATUSES.ACTIVE ? 'Yes' : 'No',
        },
        {
          id: SUBSCRIPTION_INFO.SUBSCRIPTION_VENUES,
          label: 'Venues in subscription',
          value: this.currentSubscription.quantity,
        },
        {
          id: SUBSCRIPTION_INFO.LAST_PAYMENT,
          label: 'Last payment',
          value: this.currentSubscription.billingInfo?.lastPaymentDate ? new Date(this.currentSubscription.billingInfo?.lastPaymentDate).toLocaleDateString() : '-',
        },
        {
          id: SUBSCRIPTION_INFO.EMAIL,
          label: 'Email',
          value: this.currentSubscription.billingInfo?.customerEmail || '-',
        },
        {
          id: SUBSCRIPTION_INFO.SUBSCRIPTION_STATUS,
          label: 'Subscription status',
          value: this.$t(`subscriptionStatus.${this.currentSubscription.status}`),
          canBeRemoved: [SUBSCRIPTION_STATUSES.AWAIT_CONFIRMATION, SUBSCRIPTION_STATUSES.AWAIT_PAYMENT, SUBSCRIPTION_STATUSES.DRAFT].includes(this.currentSubscription.status),
        },
      ] : [];
    },
    workplacesList() {
      return this.currentSubscription?.workplaces.map((workplace) => ({
        label: workplace.name || 'No name',
        value: workplace.status ? this.$t(`locationStatus.${workplace.status}`) : '-',
        additionalValue: this.getTipsTypeValue(workplace.tipsType),
        warning: workplace.status === LOCATION_STATUSES.NOT_ACTIVE,
      })) || [];
    },
    activeWorkplaces() {
      return this.currentSubscription?.workplaces.filter((workplace) => workplace.status === LOCATION_STATUSES.ACTIVE) || [];
    },
    selectorOwners() {
      return this.owners
        .filter((owner) => !owner.newest)
        .map((owner) => ({
          value: owner.id,
          name: [owner.phoneNumber, owner.email]
            .filter((elem) => elem)
            .join(', '),
        }));
    },
    disableForm() {
      return this.currentSubscription && this.currentSubscription.status !== SUBSCRIPTION_STATUSES.DRAFT;
    },
    currentSubscriptionIsDraft() {
      return this.currentSubscription?.status === SUBSCRIPTION_STATUSES.DRAFT;
    },
    isSimilarLocationTypes() {
      const actualTipsTypes = new Set();
      this.activeWorkplaces.forEach((workplace) => actualTipsTypes.add(workplace.tipsType));
      return actualTipsTypes.size < 2;
    },
  },

  methods: {
    getTipsTypeValue(tipsType) {
      switch (tipsType) {
        case 'MULTI_PERSONAL':
        case 'PERSONAL':
          return this.$t('tipsTypes.individual');
        case 'COMMON':
        case 'COMMON_PRIORITY':
          return this.$t('tipsTypes.common');
        default:
          return '';
      }
    },
    setDefaultPlan() {
      const defaultPlan = this.plans.find((plan) => plan.isDefault);
      if (defaultPlan) {
        this.form.planId = defaultPlan.id;
      }
    },
    async loadSubscriptionPlans() {
      try {
        this.plansLoading = true;
        const { data } = await api.subscription.getSubscriptionPlans(this.locationId);
        this.plans = data;
        if (!this.form.planId) {
          this.setDefaultPlan();
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.plansLoading = false;
      }
    },
    async submitSubscription() {
      if (
        await this.$confirm({
          title: 'Save changes',
          message: 'Are you sure you want to save changes and update billing info?',
          applyButton: 'Yes',
          closeButton: 'Cancel',
        })
      ) {
        try {
          this.submitLoading = true;
          await api.subscription.createSubscription(this.form);
          this.$emit('submit');
          this.$emit('input', false);
          this.$showSuccess();
        } catch (e) {
          this.$showServerError(e);
        } finally {
          this.submitLoading = false;
        }
      }
    },
    async checkCurrentSubscription() {
      try {
        this.subscriptionLoading = true;
        const { data } = await api.subscription.getCurrentSubscription(this.locationId);
        if (data.status !== SUBSCRIPTION_STATUSES.DRAFT) {
          this.currentSubscription = data;
          this.form.planId = data.planId || null;
          this.form.holderId = this.owners.find((owner) => owner.isSubscriptionHolder)?.id;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.subscriptionLoading = false;
      }
    },
    onOpened() {
      this.loadSubscriptionPlans();
      this.checkCurrentSubscription();
    },
    onClosed() {
      this.form = { ...INITIAL_FORM };
      this.currentSubscription = null;
      this.$emit('input', false);
    },
    async createSubscriptionDraftHandler() {
      if (
        this.form.holderId
        && this.form.planId
        && (!this.currentSubscription || this.currentSubscriptionIsDraft)
      ) {
        try {
          const { data } = await api.subscription.createSubscriptionDraft(this.form);
          this.currentSubscription = data;
        } catch (e) {
          this.$showServerError(e);
        }
      }
    },
    removeInfoHandler(infoId) {
      if (infoId === SUBSCRIPTION_INFO.SUBSCRIPTION_STATUS) {
        this.showRemoveSubscriptionConfirm = true;
      }
    },
    async removeSubscriptionHandler() {
      try {
        this.subscriptionLoading = true;
        await api.subscription.removeSubscription(this.currentSubscription.id);

        this.$emit('close');
        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.subscriptionLoading = false;
      }
    },
  },
  watch: {
    'form.holderId': {
      handler() {
        this.createSubscriptionDraftHandler();
      },
    },
    'form.planId': {
      handler() {
        this.createSubscriptionDraftHandler();
      },
    },
  },
};
</script>
