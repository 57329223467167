<template>
  <div class="location-kyb-form__wrapper">
    <ui-loading v-if="loading.data" />

    <validation-observer v-else tag="div" v-slot="{ validate }">
      <div class="location-kyb-form">
        <div class="location-kyb-form__section">
          <div class="location-kyb-form__section-title">
            Company information
          </div>
          <div class="location-kyb-form__fields">
            <validation-provider
              tag="div"
              name="Legal name"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.legalName"
                label="Legal name"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="Trading name"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.tradingName"
                label="Trading name"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="Business activity"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.businessActivity"
                label="Business activity"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="Website"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.website"
                label="Website"
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="Social media link"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.socialMediaLink"
                label="Social media link"
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="Contact phone or Email"
              mode="passive"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.contactInfo"
                label="Contact phone or Email"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="Country"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.country"
                :items="countries"
                label="Country"
                placeholder="Select"
                label-key="name"
                value-key="iso"
                filterable
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="City"
              mode="passive"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.city"
                label="City"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="Company registration number"
              rules="required|numeric"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.certNumber"
                label="Company registration number"
                required
                is-integer
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="Company registration address"
              mode="passive"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.registrationAddress"
                label="Company registration address"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="Company actual address"
              mode="passive"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.actualAddress"
                label="Company actual address"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="Company registration date"
              mode="passive"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-calendar-picker
                v-model="registrationDate"
                label="Company registration date"
                input-icon="calendar"
                no-direction
                required
                :error="errors[0]"
              />
            </validation-provider>
          </div>
        </div>
        <div class="location-kyb-form__section">
          <div class="location-kyb-form__section-title">
            Legal representative
          </div>
          <div class="location-kyb-form__fields">
            <validation-provider
              tag="div"
              name="Full name"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.legalRepresentative.fullName"
                label="Full name"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="Contact phone or Email"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.legalRepresentative.contactInfo"
                label="Contact phone or Email"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="City"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.legalRepresentative.city"
                label="City"
                required
                :error="errors[0]"
              />
            </validation-provider>
          </div>
        </div>
        <div class="location-kyb-form__section">
          <div class="location-kyb-form__section-title">
            Payment information
          </div>
          <div class="location-kyb-form__fields">
            <validation-provider
              tag="div"
              name="Merchant Category Code (MCC)"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.paymentInfo.mcc"
                :items="mccListItems"
                label="Merchant Category Code (MCC)"
                placeholder="Select"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="Average check, AED"
              rules="required|numeric"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.paymentInfo.averagePaymentAmount"
                label="Average check, AED"
                required
                is-integer
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="Expected money volume, AED"
              rules="required|numeric"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.paymentInfo.expectedAmount"
                label="Expected money volume, AED"
                required
                is-integer
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="Current Payment Solution"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.paymentInfo.paymentProvider"
                label="Current Payment Solution"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="Monthly Avg Transactions"
              rules="required|numeric"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.paymentInfo.numberOfTransactions"
                label="Monthly Avg Transactions"
                required
                is-integer
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="International payments,%"
              rules="required|numeric"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.paymentInfo.internationalPaymentsPercent"
                label="International payments,%"
                required
                is-integer
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              name="Reason for changing to EasyTip"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.paymentInfo.changeReason"
                label="Reason for changing to EasyTip"
                required
                :error="errors[0]"
              />
            </validation-provider>
          </div>
        </div>
        <div class="location-kyb-form__section">
          <div class="location-kyb-form__section-title">
            Payment options
          </div>
          <div class="location-kyb-form__fields">
            <div class="location-kyb-form__checkbox-column">
              <div class="location-kyb-form__checkbox-column-title">
                Type of clients
              </div>
              <ui-checkbox
                v-model="form.paymentOption.typeOfClients.B2B"
              >
                B2B
              </ui-checkbox>
              <ui-checkbox
                v-model="form.paymentOption.typeOfClients.B2C"
              >
                B2C
              </ui-checkbox>
            </div>
            <div class="location-kyb-form__checkbox-column">
              <div class="location-kyb-form__checkbox-column-title">
                How payments are made?
              </div>
              <ui-checkbox
                v-model="form.paymentOption.paymentSource.WEBSITE"
              >
                Website
              </ui-checkbox>
              <ui-checkbox
                v-model="form.paymentOption.paymentSource.CHAT"
              >
                Chats
              </ui-checkbox>
              <ui-checkbox
                v-model="form.paymentOption.paymentSource.IN_STORE"
              >
                In-store
              </ui-checkbox>
            </div>
            <div class="location-kyb-form__checkbox-column">
              <div class="location-kyb-form__checkbox-column-title">
                Expected use of the platform
              </div>
              <ui-checkbox
                v-model="form.paymentOption.typeOfUse.DEPOSITS"
              >
                Deposits
              </ui-checkbox>
              <ui-checkbox
                v-model="form.paymentOption.typeOfUse.FULL_PAYMENTS"
              >
                Full payments
              </ui-checkbox>
              <ui-checkbox
                v-model="form.paymentOption.typeOfUse.TIPS"
              >
                Tips
              </ui-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="location-kyb-form__actions">
        <ui-button
          action
          type="primary"
          :is-loading="loading.form"
          :disabled="loading.form || isActivated"
          @click.prevent="save(validate)"
        >
          {{ $t('form.buttons.save') }}
        </ui-button>

        <ui-button
          action
          type="primary"
          :is-loading="loading.activate"
          :disabled="loading.activate || !isActivateAvailable || isActivated"
          @click.prevent="activate"
        >
          Send to PayerMax
        </ui-button>

        <div
          v-if="lastActivatedDate"
          class="location-kyb-form__activated-date"
        >
          Info sent {{ new Date(lastActivatedDate).toLocaleDateString() }}
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import api from '@/api';

export default {
  name: 'LocationKYBForm',

  props: {
    locationId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      loading: {
        data: true,
        form: false,
        activate: false,
      },
      isActivateAvailable: false,
      isActivated: false,
      lastActivatedDate: null,
      mcc: [],
      form: {
        legalName: '',
        website: '',
        socialMediaLink: '',
        country: 'ae',
        city: '',
        registrationAddress: '',
        actualAddress: '',
        registrationDate: null,
        tradingName: '',
        businessActivity: '',
        contactInfo: '',
        certNumber: null,
        legalRepresentative: {
          fullName: '',
          contactInfo: '',
          city: '',
        },
        paymentInfo: {
          mcc: '',
          averagePaymentAmount: null,
          expectedAmount: null,
          paymentProvider: '',
          numberOfTransactions: null,
          internationalPaymentsPercent: null,
          changeReason: '',
        },
        paymentOption: {
          typeOfClients: {
            B2B: false,
            B2C: false,
          },
          paymentSource: {
            WEBSITE: false,
            CHAT: false,
            IN_STORE: false,
          },
          typeOfUse: {
            DEPOSITS: false,
            FULL_PAYMENTS: false,
            TIPS: false,
          },
        },
      },
    };
  },

  computed: {
    registrationDate: {
      get() {
        return this.form.registrationDate ? new Date(this.form.registrationDate) : null;
      },
      set(value) {
        this.form.registrationDate = DateTime.fromJSDate(value).toISODate();
      },
    },
    countries() {
      return this.$getCountries({ i18n: true });
    },
    requestConfig() {
      return ({
        ...this.form,
        paymentOption: {
          typeOfClients: this.transformCheckboxes(this.form.paymentOption.typeOfClients),
          paymentSource: this.transformCheckboxes(this.form.paymentOption.paymentSource),
          typeOfUse: this.transformCheckboxes(this.form.paymentOption.typeOfUse),
        },
      });
    },
    mccListItems() {
      return this.mcc.map((mcc) => ({
        name: `${mcc.code} ${mcc.description}`,
        value: mcc.code.toString(),
      }));
    },
  },

  methods: {
    transformCheckboxes(obj) {
      return Object.keys(obj)
        .filter((key) => obj[key])
        .map((key) => key);
    },
    async getMCCCodes() {
      const { data } = await api.locations.getMCC();

      this.mcc = data;
    },
    async getKYBInfo() {
      try {
        this.loading.data = true;

        await this.getMCCCodes();

        const { data } = await api.locations.getKYBConfig(this.locationId);
        const {
          isActivated,
          lastActivatedDate,
          ...clearForm
        } = data;

        this.isActivateAvailable = Boolean(data.legalName);
        this.isActivated = isActivated;
        this.lastActivatedDate = lastActivatedDate;

        this.form = {
          ...clearForm,
          paymentOption: {
            typeOfClients: {
              B2B: data.paymentOption.typeOfClients.includes('B2B'),
              B2C: data.paymentOption.typeOfClients.includes('B2C'),
            },
            paymentSource: {
              WEBSITE: data.paymentOption.paymentSource.includes('WEBSITE'),
              CHAT: data.paymentOption.paymentSource.includes('CHAT'),
              IN_STORE: data.paymentOption.paymentSource.includes('IN_STORE'),
            },
            typeOfUse: {
              DEPOSITS: data.paymentOption.typeOfUse.includes('DEPOSITS'),
              FULL_PAYMENTS: data.paymentOption.typeOfUse.includes('FULL_PAYMENTS'),
              TIPS: data.paymentOption.typeOfUse.includes('TIPS'),
            },
          },
        };
      } catch (e) {
        // noop
      } finally {
        this.loading.data = false;
      }
    },
    async save(validate) {
      try {
        this.loading.form = true;

        if (!await validate()) {
          this.$warning({
            message: this.$t('alerts.requiredModal.message'),
          });

          return;
        }

        await api.locations.setKYBConfig(this.locationId, this.requestConfig);
        await this.getKYBInfo();

        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.form = false;
      }
    },
    async activate() {
      try {
        this.loading.activate = true;

        await api.locations.activateKYBForm(this.locationId);
        await this.getKYBInfo();

        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.activate = false;
      }
    },
  },

  mounted() {
    this.getKYBInfo();
  },
};
</script>

<style lang="scss">
.location-kyb-form {
  display: flex;
  flex-direction: column;

  &__wrapper {
    min-height: 1000px;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-top: 18px;
  }

  &__activated-date {
    color: #808080;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 18px;

    &-title {
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      color: #777777;
    }
  }

  &__fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 40px;
  }

  &__checkbox-column {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &-title {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      color: #777777;
    }
  }

  .form-group {
    margin-top: 12px;
  }
}
</style>
