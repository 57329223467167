import { PAYMENT_SYSTEM } from '@/config/paymentSystems';

const {
  // gb ryft qr bill commissions
  VUE_APP_GB_RYFT_PROCESSING_FIXED_COMMISSION,
  VUE_APP_GB_RYFT_PROCESSING_VARIABLE_COMMISSION,
  VUE_APP_GB_RYFT_PROCESSING_SETTLEMENT_COMMISSION,
  VUE_APP_GB_RYFT_DAYS_UNTIL_SETTLEMENT,
  // ae payermax qr bill commissions
  VUE_APP_AE_PAYER_MAX_PROCESSING_FIXED_COMMISSION,
  VUE_APP_AE_PAYER_MAX_PROCESSING_VARIABLE_COMMISSION,
  VUE_APP_AE_PAYER_MAX_PROCESSING_SETTLEMENT_COMMISSION,
  VUE_APP_AE_PAYER_MAX_DAYS_UNTIL_SETTLEMENT,
} = process.env;

export const QR_BILL_COMMISSION_LABELS = {
  PROCESSING_FIXED_COMMISSION: 'processingFixedCommission',
  PROCESSING_VARIABLE_COMMISSION: 'processingVariableCommission',
  PROCESSING_SETTLEMENT_COMMISSION: 'processingSettlementCommission',
  DAYS_UNTIL_SETTLEMENT: 'daysUntilSettlement',
};

export const QR_BILL_COMMISSION_CONFIG = {
  gb: {
    [PAYMENT_SYSTEM.RYFT]: {
      [QR_BILL_COMMISSION_LABELS.PROCESSING_FIXED_COMMISSION]: parseFloat(VUE_APP_GB_RYFT_PROCESSING_FIXED_COMMISSION),
      [QR_BILL_COMMISSION_LABELS.PROCESSING_VARIABLE_COMMISSION]: parseFloat(VUE_APP_GB_RYFT_PROCESSING_VARIABLE_COMMISSION),
      [QR_BILL_COMMISSION_LABELS.PROCESSING_SETTLEMENT_COMMISSION]: parseFloat(VUE_APP_GB_RYFT_PROCESSING_SETTLEMENT_COMMISSION),
      [QR_BILL_COMMISSION_LABELS.DAYS_UNTIL_SETTLEMENT]: parseInt(VUE_APP_GB_RYFT_DAYS_UNTIL_SETTLEMENT, 10),
    },
  },
  ae: {
    [PAYMENT_SYSTEM.PAYER_MAX]: {
      [QR_BILL_COMMISSION_LABELS.PROCESSING_FIXED_COMMISSION]: parseFloat(VUE_APP_AE_PAYER_MAX_PROCESSING_FIXED_COMMISSION),
      [QR_BILL_COMMISSION_LABELS.PROCESSING_VARIABLE_COMMISSION]: parseFloat(VUE_APP_AE_PAYER_MAX_PROCESSING_VARIABLE_COMMISSION),
      [QR_BILL_COMMISSION_LABELS.PROCESSING_SETTLEMENT_COMMISSION]: parseFloat(VUE_APP_AE_PAYER_MAX_PROCESSING_SETTLEMENT_COMMISSION),
      [QR_BILL_COMMISSION_LABELS.DAYS_UNTIL_SETTLEMENT]: parseInt(VUE_APP_AE_PAYER_MAX_DAYS_UNTIL_SETTLEMENT, 10),
    },
  },
};

export const getQrBillCommissionConfig = (country, paymentSystem) => {
  if (!country || !paymentSystem) {
    return null;
  }

  return QR_BILL_COMMISSION_CONFIG[country]?.[paymentSystem];
};
