<template>
  <div class="location-user">
    <ui-input :class="inputClass">
      <ui-checkbox
        v-model="checked"
        :disabled="disabled"
      >
        {{ label }}
      </ui-checkbox>
    </ui-input>
    <template v-if="checked">
      <div v-for="item in visibleItems" :key="item.id">
        <div class="location-user__content">
          <ui-icon-button
            name="info"
            size="20px"
            class="location-user__info"
            :disabled="!item.login"
            @click="openInfo(item)"
          />
          <div class="location-user__inputs">
            <ui-phone
              v-model="item.phoneNumber"
              :country-code="countryCode"
              class="location-user__input"
              :disabled="!item.newest"
            />
            <ui-input
              v-if="isOwner"
              v-model="item.email"
              class="location-user__input"
              placeholder="Email"
              :disabled="!item.newest"
            />
          </div>
          <ui-icon
            v-if="item.isSubscriptionHolder"
            name="star"
            size="26"
            class="location-user__star"
            :class="{
              'location-user__star--active': subscriptionIsActive,
            }"
          />
          <div class="location-user__actions">
            <ui-action-down
              v-if="getUserActions(item).length > 1 && !item.newest"
              :items="getUserActions(item)"
              right-align
              @select="handleAction($event, item)"
              @opened="handleOpenActions(item)"
            />
            <ui-icon-button
              v-else
              name="trash"
              size="18px"
              class="text-primary"
              @click="remove(item)"
            />
          </div>
        </div>
      </div>
      <div class="mb-4">
        <a
          href="javascript:;"
          @click="add()"
          class="text-primary"
        >
          Add more
        </a>
      </div>

      <staff-info-modal
        v-if="showInfo.staff"
        :show="showInfo.show"
        :title="`${label} info`"
        :staff="showInfo.staff"
        :location="location || null"
        :attributes="showInfo.attributes"
        :buttons="showInfo.buttons"
        @closed="closeInfo"
      />
    </template>

    <change-email-modal
      v-if="emailModal.show"
      :user="emailModal.user"
      :user-role="userRole"
      @completed="$emit('completed')"
      @close="emailModal.show = false"
    />

    <user-change-phone-modal
      v-if="phoneModal.show"
      :user="phoneModal.user"
      @completed="$emit('completed')"
      @close="emailModal.show = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/api';
import hex from '@/utils/hex';
import StaffInfoModal from '@/components/Staff/StaffInfoModal.vue';
import { OWNER_ACTIONS } from '@/config/ownerActions';
import ChangeEmailModal from '@/components/ChangeEmailModal.vue';
import UserChangePhoneModal from '@/components/User/UserChangePhoneModal.vue';
import { SUBSCRIPTION_STATUSES } from '@/config/subscriptionStatuses';
import Timer from '@/class/Timer';
import parseDate from '@/utils/parseDate';

const INITIAL_REGISTRATION_MESSAGE = {
  timer: null,
  disabled: true,
};

export default {

  components: {
    UserChangePhoneModal,
    ChangeEmailModal,
    StaffInfoModal,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
    subscription: {
      type: Object,
      default: () => {},
    },
    label: String,
    inputClass: String,
    disabled: Boolean,
    countryCode: String,
    userRole: String,
  },

  data: () => ({
    SUBSCRIPTION_STATUSES,
    showInfo: {
      show: false,
      staff: null,
      attributes: [
        'NAME',
        'EMAIL',
        'LOGIN',
      ],
      buttons: [
        'COPY_INFO',
        'VIEW_AS',
      ],
    },
    emailModal: {
      show: false,
      user: null,
    },
    phoneModal: {
      show: false,
      user: null,
    },
    registrationMessage: { ...INITIAL_REGISTRATION_MESSAGE },
  }),

  computed: {
    ...mapGetters('auth', [
      'isSupportAdmin',
    ]),
    subscriptionIsActive() {
      return this.subscription?.status === SUBSCRIPTION_STATUSES.ACTIVE;
    },
    isOwner() {
      return this.userRole === 'owners';
    },
    visibleItems() {
      return this.items.filter(({ removed }) => !removed);
    },
    checked: {
      get() {
        return this.visibleItems.length > 0;
      },
      set(checked) {
        if (checked) {
          this.add();
        } else {
          this.clear();
        }
      },
    },
  },

  watch: {
    disabled(disabled) {
      if (disabled) {
        this.checked = false;
      }
    },
  },

  methods: {
    async sendSubscriptionEmail(user) {
      if (!user.email) {
        await this.$alert({
          title: 'Error',
          message: 'Owner has no Email assigned.</br>Unable to send a subscription email.',
          applyButton: this.$t('form.buttons.ok'),
        });
      } else {
        try {
          await api.subscription.sendSubscriptionEmail(user.id);
          this.$showSuccess();
        } catch (e) {
          this.$showServerError(e);
        }
      }
    },
    getUserActions(user) {
      return [
        {
          id: OWNER_ACTIONS.SEND_REGISTRATION_LINK,
          label: this.$t(`ownerActions.${OWNER_ACTIONS.SEND_REGISTRATION_LINK}`),
          icon: 'pencil',
          visible: this.isOwner,
          disabled: this.registrationMessage.disabled,
        },
        {
          id: OWNER_ACTIONS.SEND_SUBSCRIPTION_EMAIL,
          label: this.$t(`ownerActions.${OWNER_ACTIONS.SEND_SUBSCRIPTION_EMAIL}`),
          icon: 'bill',
          visible: this.isOwner && user.isSubscriptionHolder,
        },
        {
          id: OWNER_ACTIONS.CHANGE_PHONE,
          label: this.$t(`ownerActions.${OWNER_ACTIONS.CHANGE_PHONE}`),
          icon: 'handset',
          visible: this.isSupportAdmin,
        },
        {
          id: OWNER_ACTIONS.CHANGE_EMAIL,
          label: this.$t(`ownerActions.${OWNER_ACTIONS.CHANGE_EMAIL}`),
          icon: 'email',
          visible: this.isOwner,
        },
        {
          id: OWNER_ACTIONS.DELETE,
          label: this.$t(`ownerActions.${OWNER_ACTIONS.DELETE}`),
          icon: 'trash',
          visible: true,
        },
      ].filter((action) => action.visible);
    },
    changeEmailHandler(user) {
      this.emailModal.user = user;
      this.emailModal.show = true;
    },
    changePhoneHandler(user) {
      this.phoneModal.user = user;
      this.phoneModal.show = true;
    },
    add() {
      this.items.push({
        id: hex(16),
        phoneNumber: '',
        newest: true,
        removed: false,
      });
    },
    async remove(item) {
      if (item.isSubscriptionHolder && this.subscriptionIsActive) {
        await this.$alert({
          title: 'Error',
          message: 'Unable to delete owner who holds subscription. Press Manage subscription button to assign new subscription holder first.',
          applyButton: this.$t('form.buttons.ok'),
        });
      } else if (
        item.newest
        || await this.$confirm({
          title: 'Confirmation',
          message: 'Are you sure you want to delete this user? Deleted users will no longer have access to tip distributions.',
          applyButton: 'Yes',
        })
      ) {
        item.removed = true;
      }
    },
    clear() {
      this.items.forEach((item) => {
        item.removed = true;
      });
    },
    openInfo(item) {
      if (this.userRole === 'owners'
        && !this.showInfo.attributes.includes('MONTHLY_REPORT')
      ) {
        this.showInfo.attributes.push('MONTHLY_REPORT');
      }

      this.showInfo.staff = item;
      this.showInfo.show = true;
    },
    closeInfo() {
      this.showInfo.show = false;
      this.showInfo.staff = null;
    },
    handleAction(event, item) {
      switch (event) {
        case OWNER_ACTIONS.SEND_REGISTRATION_LINK:
          this.resendInvitation(item);
          break;

        case OWNER_ACTIONS.SEND_SUBSCRIPTION_EMAIL:
          this.sendSubscriptionEmail(item);
          break;

        case OWNER_ACTIONS.CHANGE_PHONE:
          this.changePhoneHandler(item);
          break;

        case OWNER_ACTIONS.CHANGE_EMAIL:
          this.changeEmailHandler(item);
          break;

        case OWNER_ACTIONS.DELETE:
          this.remove(item);
          break;

        default:
          break;
      }
    },
    getResendInvitationTimeout(lastSendTime) {
      const currentTime = +new Date();
      const sendTime = +parseDate(lastSendTime);
      const TIMEOUT = 2 * 60 * 1000;

      return sendTime + TIMEOUT - currentTime;
    },
    resetResendInvitation() {
      if (this.registrationMessage.timer) {
        this.registrationMessage.timer.stop();
      }

      this.registrationMessage = { ...INITIAL_REGISTRATION_MESSAGE };
    },
    async loadResendInvitationData({ id }) {
      this.resetResendInvitation();

      try {
        const {
          data: {
            lastSendTime,
            remainingAttempts,
          },
        } = await api.owners.getRegistrationMessage({
          id,
          locationId: this.location.id,
        });

        if (lastSendTime) {
          const timeout = this.getResendInvitationTimeout(lastSendTime);

          if (timeout > 0) {
            this.registrationMessage.timer = new Timer(timeout, () => {
              if (remainingAttempts > 0) {
                this.registrationMessage.disabled = false;
              }

              this.registrationMessage.timer = null;
            });
          }
        }

        if (remainingAttempts > 0 && !this.registrationMessage.timer) {
          this.registrationMessage.disabled = false;
        }
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async resendInvitation(user) {
      try {
        await api.owners.sendRegistrationMessage({
          id: user.id,
          locationId: this.location.id,
        });

        await this.loadResendInvitationData(user);

        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      }
    },
    handleOpenActions(user) {
      this.loadResendInvitationData(user);
    },
  },
  beforeDestroy() {
    this.resetResendInvitation();
  },
};
</script>
