<template>
  <form class="location-kyb-files" @submit.prevent>
    <ui-loading
      v-if="isLoading"
      inline
      class="location-kyb-files__loader"
    />

    <div
      v-else
      class="location-kyb-files__wrapper"
    >
      <div
        v-for="file in KYB_FILE_LIST"
        :key="file.type"
        class="location-kyb-files__file"
      >
        <ui-icon
          name="pdf-outline"
          size="20px"
        />

        <div
          class="location-kyb-files__label"
          :class="{ 'location-kyb-files__label--link': currentFiles[file.value] }"
          @click="currentFiles[file.value] ? downloadFile(file.type) : null"
        >
          {{ file.label }}
        </div>

        <div class="location-kyb-files__actions">
          <ui-loading
            v-if="file.type === loadingFile"
            inline
            class="location-kyb-files__loader"
          />

          <ui-icon-button
            v-else-if="currentFiles[file.value]"
            name="trash"
            size="20px"
            class="location-kyb-files__file-delete"
            @click="deleteFile(file.type)"
          />

          <ui-button-upload
            v-else
            class="location-kyb-files__file-upload"
            title="Add file"
            accept-files="application/pdf image/*"
            @upload.stop="uploadFile($event, file.type)"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import api from '@/api';
import toBase64 from '@/utils/toBase64';
import download from '@/utils/download';

const KYB_FILE_TYPE = {
  TRADING_LICENSE: 'TRADING_LICENSE',
  EID_REPRESENTATIVE: 'EID_REPRESENTATIVE',
  MEMORANDUM_OF_UNDERSTANDING: 'MEMORANDUM_OF_UNDERSTANDING',
  BANK_ACCOUNT_CONFIRMATION: 'BANK_ACCOUNT_CONFIRMATION',
  EJARI_CERTIFICATE: 'EJARI_CERTIFICATE',
};

const KYB_FILE_LIST = [
  {
    type: KYB_FILE_TYPE.TRADING_LICENSE,
    label: 'Trading licence',
    value: 'isTradingLicenseUploaded',
  },
  {
    type: KYB_FILE_TYPE.EID_REPRESENTATIVE,
    label: 'EID of licence representative',
    value: 'isEidRepresentativeUploaded',
  },
  {
    type: KYB_FILE_TYPE.MEMORANDUM_OF_UNDERSTANDING,
    label: 'Memorandum of Understanding',
    value: 'isMemorandumOfUnderstandingUploaded',
  },
  {
    type: KYB_FILE_TYPE.BANK_ACCOUNT_CONFIRMATION,
    label: 'Bank Account Confirmation',
    value: 'isBankAccountConfirmationUploaded',
  },
  {
    type: KYB_FILE_TYPE.EJARI_CERTIFICATE,
    label: 'Ejari Certificate',
    value: 'isEjariCertificateUploaded',
  },
];

const parseFile = (fileData) => {
  const uploadedImage = Array.from(fileData.target.files)[0];

  return {
    fileName: uploadedImage.name,
    fileSize: uploadedImage.size,
    file: uploadedImage,
  };
};

export default {
  name: 'LocationKYBFiles',

  props: {
    locationId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      loadingFile: null,
      currentFiles: {},
      KYB_FILE_LIST,
    };
  },

  methods: {
    async getFiles(skipLoader = false) {
      try {
        if (!skipLoader) {
          this.isLoading = true;
        }

        const { data } = await api.locations.getKYBFiles(this.locationId);

        this.currentFiles = data;
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.isLoading = false;
      }
    },
    async uploadFile(payload, fileType) {
      const parsedFile = parseFile(payload);

      this.loadingFile = fileType;

      try {
        const base64File = await toBase64(parsedFile.file);

        await api.locations.uploadKYBFile({
          locationId: this.locationId,
          file: base64File,
          documentType: fileType,
          fileName: parsedFile.fileName,
        });

        await this.getFiles(true);
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loadingFile = null;
      }
    },
    async downloadFile(documentType) {
      try {
        this.loadingFile = documentType;

        const { data, fileName } = await api.locations.downloadKYBFile(this.locationId, documentType);

        download(data, fileName);
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loadingFile = null;
      }
    },
    async deleteFile(documentType) {
      try {
        this.loadingFile = documentType;

        await api.locations.deleteKYBFile(this.locationId, documentType);

        await this.getFiles(true);
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loadingFile = null;
      }
    },
  },

  mounted() {
    this.getFiles();
  },
};
</script>

<style lang="scss">
.location-kyb-files {
  display: flex;
  min-height: 168px;
  width: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: fit-content;
    min-width: 350px;
  }

  &__loader {
    margin: auto;
  }

  &__file {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;

    &-delete {
      color: #A01212;
    }
  }

  &__label {
    line-height: 24px;

    &--link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: auto;
  }

  &__loader {
    color: #A01212;
  }
}
</style>
