var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"location-pay-settings__wrapper"},[(_vm.loading.data)?_c('ui-loading'):_c('validation-observer',{staticClass:"location-pay-settings",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [(_vm.showSelectPayTextSection)?[_c('div',{staticClass:"location-pay-settings__subtitle"},[_vm._v(" Captions ")]),_c('div',{staticClass:"location-pay-settings__inputs"},[_c('validation-provider',{staticClass:"location-pay-settings__field",attrs:{"tag":"div","name":_vm.$t('form.labels.caption.sublocations'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{staticClass:"location-pay-settings__input",attrs:{"label":_vm.$t('form.labels.caption.sublocations'),"required":"","error":errors[0]},model:{value:(_vm.form.selectSubLocationText),callback:function ($$v) {_vm.$set(_vm.form, "selectSubLocationText", $$v)},expression:"form.selectSubLocationText"}}),_c('img',{attrs:{"src":require("@/assets/images/pay/pay-sublocations.svg")}})]}}],null,true)}),_c('validation-provider',{staticClass:"location-pay-settings__field",attrs:{"tag":"div","name":_vm.$t('form.labels.caption.groups'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{staticClass:"location-pay-settings__input",attrs:{"label":_vm.$t('form.labels.caption.groups'),"required":"","error":errors[0]},model:{value:(_vm.form.selectGroupText),callback:function ($$v) {_vm.$set(_vm.form, "selectGroupText", $$v)},expression:"form.selectGroupText"}}),_c('img',{attrs:{"src":require("@/assets/images/pay/pay-groups.svg")}})]}}],null,true)}),_c('validation-provider',{staticClass:"location-pay-settings__field",attrs:{"tag":"div","name":_vm.$t('form.labels.caption.staffList'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{staticClass:"location-pay-settings__input",attrs:{"label":_vm.$t('form.labels.caption.staffList'),"required":"","error":errors[0]},model:{value:(_vm.form.selectStaffText),callback:function ($$v) {_vm.$set(_vm.form, "selectStaffText", $$v)},expression:"form.selectStaffText"}}),_c('img',{attrs:{"src":require("@/assets/images/pay/pay-staff-list.svg")}})]}}],null,true)})],1)]:_vm._e(),_c('div',{staticClass:"location-pay-settings__subtitle"},[_vm._v(" Banner on the payment success page ")]),_c('div',{staticClass:"location-pay-settings__inputs"},[_c('validation-provider',{staticClass:"location-pay-settings__field",attrs:{"tag":"div","name":_vm.$t('form.labels.selectBanner'),"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.BANNER_VARIANTS,"label":_vm.$t('form.labels.selectBanner'),"placeholder":_vm.$t('form.placeholders.select'),"filterable":"","error":errors[0]},model:{value:(_vm.form.bannerVariant),callback:function ($$v) {_vm.$set(_vm.form, "bannerVariant", $$v)},expression:"form.bannerVariant"}})]}}],null,true)})],1),_c('ui-button',{staticClass:"location-pay-settings__submit",attrs:{"action":"","type":"primary","is-loading":_vm.loading.form,"disabled":_vm.loading.form},on:{"click":function($event){$event.preventDefault();return _vm.submit(validate)}}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.save'))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }